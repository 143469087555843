// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy&policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-privacy-policy-moutain-bus-js": () => import("./../../../src/pages/privacy&policy/moutain-bus.js" /* webpackChunkName: "component---src-pages-privacy-policy-moutain-bus-js" */),
  "component---src-pages-privacy-policy-water-ride-js": () => import("./../../../src/pages/privacy&policy/water-ride.js" /* webpackChunkName: "component---src-pages-privacy-policy-water-ride-js" */),
  "component---src-pages-terms-and-conditions-index-js": () => import("./../../../src/pages/terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-js" */),
  "component---src-pages-terms-and-conditions-moutain-bus-js": () => import("./../../../src/pages/terms-and-conditions/moutain-bus.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-moutain-bus-js" */)
}

